import Store from '../../store'
import { errorHandler, successHandler } from '../helpers/handler'
import adminHttp from './http'

const axios = adminHttp
const URL = `bill-payment`

export default {
  data() {
    return {
      getHistory: async (params) => {
        const endpoint = `${URL}/history`
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
